import React from 'react';
import Header from './Header';
import '../styles/normalize.css';
import '../styles/styles.scss';
import { AnimatePresence, motion } from 'framer-motion';

const variants = {
  pre: {
    opacity: 0,
    y: '-1rem',
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'tween',
      duration: 0.2,
    },
  },
  post: {
    opacity: 0,
    y: '1rem',
    transition: {
      type: 'tween',
      duration: 0.2,
    },
  },
};

export default function Layout({ children, location, ...props }) {
  return (
    <div>
      <Header />
      <AnimatePresence exitBeforeEnter>
        <motion.main key={children?.props?.path} variants={variants} initial="pre" animate="visible" exit="post">
          {children}
        </motion.main>
      </AnimatePresence>
    </div>
  );
}
