import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { headerHolder } from './Header.module.scss';

export default function Header() {
  return (
    <div className={headerHolder}>
      <StaticImage src="../images/Is This OK.jpg" alt="Is This OK? by Harriet Gibsone" className="bookCover" width={100} />
      <h1>Harriet Gibsone</h1>
      <ul>
        <li>
          <Link to="/" activeClassName="active">
            About
          </Link>
        </li>
        <li>
          <Link to="/work" activeClassName="active">
            Work
          </Link>
        </li>
        <li>
          <Link to="/contact" activeClassName="active">
            Contact
          </Link>
        </li>
      </ul>
    </div>
  );
}
